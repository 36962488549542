import axios from '@axios'
import { GetLocalDate } from '@/utils/utils'

const baseURL = process.env.VUE_APP_API_URL + '/pamis/admin/'
export default {
  namespaced: true,
  state: {
    deviceOptions: [],
    calendarOptions: [
      {
        color: 'success',
        label: 'Effort',
      },
      {
        color: 'danger',
        label: 'Stoppage',
      },

    ],
    selectedCalendars: ['Effort', 'Stoppage'],
  },
  getters: {
    GetDeviceOptions(state) {
      return state.deviceOptions
    },

  },
  mutations: {
    SetJobFields(state, val) {
      state.deviceOptions = val.devices
    },
    SET_SELECTED_EVENTS(state, val) {
      state.selectedCalendars = val
    },
  },
  actions: {
    fetchJobEffortFields(_, param) {
      return new Promise((resolve,) => {
        axios
          .get(`${baseURL}workorder/JobEffort/JobEffortFields?deviceId=${param ?? 13}`)
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            resolve(error)
          })
      })
    },
    fetchJobFields(ctx) {
      return new Promise((resolve,) => {
        axios
          .get(`${baseURL}workorder/Job/JobFields`)
          .then((response) => {
            ctx.commit("SetJobFields", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            ctx.commit("SetJobFields", null);
            resolve(error);
          });
      })
    },
    fetchEvents() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${baseURL}workorder/JobEffort/JobEfforts`)
          .then(response => {
            resolve(response)
          }
          )
          .catch(error => reject(error))
      })
    },
    addEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        if (event.extendedProps.stoppages.length > 0) {
          event.extendedProps.stoppages.forEach(e => {
            e.startDate = GetLocalDate(e.startDate)
            e.endDate = GetLocalDate(e.endDate)
          })
        }
        event.startDate = GetLocalDate(event.extendedProps.startDate);
        event.endDate = GetLocalDate(event.extendedProps.endDate);
        event.comments = event.title
        event.jobId = event.extendedProps.jobId
        event.stoppages = event.extendedProps.stoppages
        axios
          .post(`${baseURL}workorder/JobEffort/Create`, { jobEffort: event })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateEvent(ctx, { event }) {
      event.startDate = GetLocalDate(event.extendedProps.startDate);
      event.endDate = GetLocalDate(event.extendedProps.endDate);
      if (event.extendedProps.calendar == 'Stoppage') {
        event.type = event.title;
        return new Promise((resolve, reject) => {
          axios
            .put(`${baseURL}workorder/JobEffort/UpdateStoppage?id=${event.id}`, { stoppage: event })
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      }
      if (event.extendedProps.stoppages.length > 0) {
        event.extendedProps.stoppages.forEach(e => {
          e.startDate = GetLocalDate(e.startDate)
          e.endDate = GetLocalDate(e.endDate)
        })
      }
      event.comments = event.title
      event.jobId = event.extendedProps.jobId
      event.stoppages = event.extendedProps.stoppages.map(e => { return { type: e.type, startDate: e.startDate, endDate: e.endDate } })
      return new Promise((resolve, reject) => {
        axios
          .put(`${baseURL}workorder/JobEffort/Update?id=${event.id}`, { jobEffort: event })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeEvent(ctx, { id, calendarType }) {
      if (calendarType == 'Stoppage') {
        return new Promise((resolve, reject) => {
          axios
            .delete(`${baseURL}workorder/JobEffort/DeleteStoppage?id=${id}`)
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      }
      return new Promise((resolve, reject) => {
        axios
          .delete(`${baseURL}workorder/JobEffort/Delete?id=${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
