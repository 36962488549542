<template>
  <div>
    <b-sidebar
      id="sidebar-add-new-event"
      sidebar-class="sidebar-lg"
      :visible="isEventHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-event-handler-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">
            {{
              eventLocal.extendedProps.calendar == "Stoppage"
                ? $t("Stoppage")
                : $t("Effort")
            }}
            {{ eventLocal.id ? $t("Update") : $t("Add") }}
          </h5>
          <div>
            <feather-icon
              v-if="eventLocal.id"
              icon="TrashIcon"
              class="cursor-pointer"
              @click="
                $emit('remove-event');
                hide();
              "
            />
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <!-- Body -->
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <!-- Type -->
            <validation-provider
              v-if="eventLocal.id"
              #default="validationContext"
              name="Type"
              rules="required"
            >
              <b-form-group
                :label="$t('Type')"
                label-for="calendar"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="eventLocal.extendedProps.calendar"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="calendarOptions"
                  label="label"
                  :disabled="true"
                  :reduce="(calendar) => calendar.label"
                  input-id="Type"
                >
                  <template #option="{ color, label }">
                    <div
                      class="rounded-circle d-inline-block mr-50"
                      :class="`bg-${color}`"
                      style="height: 10px; width: 10px"
                    />
                    <span> {{ label }}</span>
                  </template>

                  <template #selected-option="{ color, label }">
                    <div
                      class="rounded-circle d-inline-block mr-50"
                      :class="`bg-${color}`"
                      style="height: 10px; width: 10px"
                    />
                    <span> {{ label }}</span>
                  </template>
                </v-select>
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <div>
              <!-- Device -->
              <validation-provider
                v-if="!eventLocal.id"
                #default="validationContext"
                name="Device"
                rules="required"
              >
                <b-form-group
                  :label="$t('Device')"
                  label-for="calendar"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="eventLocal.extendedProps.deviceId"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="deviceOptions"
                    label="label"
                    :reduce="(device) => device.id"
                    input-id="Device"
                  >
                    <template slot="selected-option" slot-scope="option">
                      <span>{{ option.name }}</span>
                    </template>
                    <template #option="{ id, name }">
                      <span> {{ name }}</span
                      ><br />
                      <small class="text-muted"> {{ id }} </small>
                    </template>
                  </v-select>

                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <!-- Job -->
              <validation-provider
                v-if="!eventLocal.id"
                #default="validationContext"
                name="Job"
              >
                <b-form-group
                  :label="$t('Job')"
                  label-for="calendar"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="eventLocal.extendedProps.jobId"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="jobOptions"
                    label="label"
                    :reduce="(job) => job.id"
                    input-id="Job"
                  >
                    <template slot="selected-option" slot-scope="option">
                      <span>{{ option.code }}</span>
                    </template>
                    <template #option="{ id, code }">
                      <span> {{ code }}</span
                      ><br />
                      <small class="text-muted"> {{ id }} </small>
                    </template>
                  </v-select>

                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </div>
            <!-- Title -->
            <validation-provider
              v-if="eventLocal.extendedProps.calendar == 'Effort'"
              #default="validationContext"
              name="Comment"
              rules="required"
            >
              <b-form-group :label="$t('Comment')" label-for="event-title">
                <b-form-textarea
                  id="event-title"
                  v-model="eventLocal.title"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Event Title"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <validation-provider
              v-else
              #default="validationContext"
              name="Comment"
              rules="required"
            >
              <b-form-group
                :label="$t('Type')"
                label-for="event-title"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="eventLocal.title"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="stoppageTypeOptions"
                  label="label"
                  input-id="Type"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Calendar -->

            <b-row>
              <b-col>
                <!-- Start Date -->
                <validation-provider
                  #default="validationContext"
                  name="Start Date"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('Start Date')"
                    label-for="start-date"
                    :state="getValidationState(validationContext)"
                  >
                    <flat-pickr
                      v-model="eventLocal.extendedProps.startDate"
                      class="form-control"
                      :config="{
                        enableTime: true,
                        locale: $i18n.t('locale_datePickr'),
                        time_24hr: true,
                        dateFormat: 'Y-m-d H:i',
                      }"
                    />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col>
                <!-- Start Date -->
                <validation-provider
                  #default="validationContext"
                  :label="$t('End Date')"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('End Date')"
                    label-for="start-date"
                    :state="getValidationState(validationContext)"
                  >
                    <flat-pickr
                      v-model="eventLocal.extendedProps.endDate"
                      class="form-control"
                      :config="{
                        enableTime: true,
                        locale: $i18n.t('locale_datePickr'),
                        time_24hr: true,
                        dateFormat: 'Y-m-d H:i',
                      }"
                    />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <!-- Stoppage Section -->
            <b-row
              class="px-0 mx-0"
              v-if="
                eventLocal.extendedProps.calendar == 'Effort' || !eventLocal.id
              "
            >
              <b-col class="px-0 mx-0">
                <a
                  style="color: blue; font-weight: bold"
                  @click="showStoppages"
                >
                  <feather-icon
                    :id="`invoice-row-preview-icon`"
                    icon="ChevronsDownIcon"
                    size="16"
                    class="cursor-pointer mx-50"
                  />
                  <span>{{ $t("Show Stoppages") }} </span></a
                >
              </b-col>
              <b-col class="px-0 mx-0" v-if="isStoppageVisible">
                <a style="color: blue; font-weight: bold" @click="addStoppage">
                  <feather-icon
                    :id="`invoice-row-preview-icon`"
                    icon="PlusCircleIcon"
                    size="16"
                    class="cursor-pointer mx-50"
                  />
                  <span>{{ $t("Add Stoppage") }}</span>
                </a>
              </b-col>
            </b-row>
            <div
              v-if="
                isStoppageVisible &&
                eventLocal.extendedProps.stoppages.length === 0
              "
            >
              <span style="color: red"> {{ $t("No stoppages found") }}</span>
            </div>
            <div
              v-if="isStoppageVisible"
              :key="eventLocal.extendedProps.stoppages.length"
            >
              <b-row
                v-for="stoppage in eventLocal.extendedProps.stoppages"
                :key="stoppage.id"
                class="stoppage-area my-50 py-50"
              >
                <b-col>
                  <feather-icon
                    icon="XIcon"
                    class="cursor-pointer"
                    style="float: right"
                    @click="removeStoppage(stoppage)"
                  />
                  <!-- Type -->

                  <b-form-group :label="$t('Type')" label-for="event-title">
                    <v-select
                      v-model="stoppage.type"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="stoppageTypeOptions"
                      label="label"
                      input-id="Type"
                    />
                  </b-form-group>

                  <!-- Calendar -->

                  <b-row
                    ><b-col>
                      <!-- Start Date -->

                      <validation-provider
                        #default="validationContext"
                        name="Start Date"
                        rules="required"
                      >
                        <b-form-group
                          :label="$t('Start Date')"
                          label-for="start-date"
                          :state="getValidationState(validationContext)"
                        >
                          <flat-pickr
                            v-model="stoppage.startDate"
                            class="form-control"
                            :config="{
                              enableTime: true,
                              locale: $i18n.t('locale_datePickr'),
                              time_24hr: true,
                              dateFormat: 'Y-m-d H:i',
                            }"
                          />
                          <b-form-invalid-feedback
                            :state="getValidationState(validationContext)"
                          >
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col>
                      <!-- End Date -->
                      <validation-provider
                        #default="validationContext"
                        name="End Date"
                        rules="required"
                      >
                        <b-form-group
                          :label="$t('End Date')"
                          label-for="end-date"
                          :state="getValidationState(validationContext)"
                        >
                          <flat-pickr
                            v-model="stoppage.endDate"
                            class="form-control"
                            :config="{
                              enableTime: true,
                              locale: $i18n.t('locale_datePickr'),
                              time_24hr: true,
                              dateFormat: 'Y-m-d H:i',
                            }"
                          />
                          <b-form-invalid-feedback
                            :state="getValidationState(validationContext)"
                          >
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>

            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                {{ eventLocal.id ? $t("Update") : $t("Add") }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
              >
                {{ $t("Reset") }}
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BAvatar,
  BRow,
  BCol,
  BFormTextarea,
  BButton,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, url } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import { ref, toRefs, watch } from "@vue/composition-api";
import useCalendarEventHandler from "./useCalendarEventHandler";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";

export default {
  components: {
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormTextarea,
    BAvatar,
    BRow,
    BCol,
    vSelect,
    flatPickr,
    ValidationProvider,
    BFormInvalidFeedback,
    ValidationObserver,
    FeatherIcon,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isEventHandlerSidebarActive",
    event: "update:is-event-handler-sidebar-active",
  },
  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
    clearEventData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      required,
      email,
      url,
    };
  },
  setup(props, { emit }) {
    /*
     ? This is handled quite differently in SFC due to deadlock of `useFormValidation` and this composition function.
     ? If we don't handle it the way it is being handled then either of two composition function used by this SFC get undefined as one of it's argument.
     * The Trick:

     * We created reactive property `clearFormData` and set to null so we can get `resetEventLocal` from `useCalendarEventHandler` composition function.
     * Once we get `resetEventLocal` function which is required by `useFormValidation` we will pass it to `useFormValidation` and in return we will get `clearForm` function which shall be original value of `clearFormData`.
     * Later we just assign `clearForm` to `clearFormData` and can resolve the deadlock. 😎

     ? Behind The Scene
     ? When we passed it to `useCalendarEventHandler` for first time it will be null but right after it we are getting correct value (which is `clearForm`) and assigning that correct value.
     ? As `clearFormData` is reactive it is being changed from `null` to corrent value and thanks to reactivity it is also update in `useCalendarEventHandler` composition function and it is getting correct value in second time and can work w/o any issues.
    */
    const clearFormData = ref(null);
    const deleteID = ref(-1);

    const {
      eventLocal,
      resetEventLocal,
      calendarOptions,
      stoppageTypeOptions,
      deviceOptions,
      jobOptions,
      isStoppageVisible,
      // UI
      onSubmit,
    } = useCalendarEventHandler(toRefs(props), clearFormData, emit);

    const { refFormObserver, getValidationState, resetForm, clearForm } =
      formValidation(resetEventLocal, props.clearEventData);

    clearFormData.value = clearForm;
    function showStoppages() {
      if (isStoppageVisible.value) {
        isStoppageVisible.value = false;
        return;
      }
      isStoppageVisible.value = true;
    }
    function addStoppage() {
      eventLocal.value.extendedProps.stoppages.unshift({
        type: "",
        startDate: eventLocal.value.extendedProps.startDate,
        endDate: eventLocal.value.extendedProps.startDate,
      });
    }
    function removeStoppage(selectedStoppage) {
      for (let stoppageIndex in eventLocal.value.extendedProps.stoppages) {
        if (
          JSON.stringify(
            eventLocal.value.extendedProps.stoppages[stoppageIndex]
          ) === JSON.stringify(selectedStoppage)
        ) {
          eventLocal.value.extendedProps.stoppages.splice(stoppageIndex, 1);
        }
      }
    }
    return {
      // Add New Event
      eventLocal,
      calendarOptions,
      onSubmit,
      deviceOptions,
      stoppageTypeOptions,
      jobOptions,
      showStoppages,
      removeStoppage,
      isStoppageVisible,
      addStoppage,
      // Form Validation
      resetForm,
      refFormObserver,
      getValidationState,
    };
  },
};
</script>

<style lang="scss">
.stoppage-area {
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
